import React from "react";
import SF_LOGO from "../src/images/sf_logo.png";

function Footer() {
  return (
    <footer className="bg-white rounded-lg  dark:bg-gray-900 m-4">
      <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
        <div className="sm:flex sm:items-center sm:justify-between">
          {/* <a
            href="#"
            className="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse"
          >
            <img src={SF_LOGO} className="h-8" alt="Flowbite Logo" />
            <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
              SeeFood
            </span>
          </a> */}
          <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
            <li>
              <a
                href="https://foggy-amaryllis-ce1.notion.site/Privacy-Policy-for-SeeFood-a9fde891b0b34fb4a7b126ffc89967ea?pvs=74"
                className="hover:underline me-4 md:me-6"
              >
                Privacy Policy
              </a>
            </li>

            <li>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSc6WOBjZwTBHf1PHNSeOLFQDK9E6hCTh9dQjnk3w70GfJhELw/viewform?usp=sf_link"
                className="hover:underline"
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">
          © 2024{" "}
          <a href="#" className="hover:underline">
            SeeFood
          </a>
          . All Rights Reserved.
        </span>
      </div>
    </footer>
  );
}

export default Footer;
