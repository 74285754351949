import React from "react";
import SF_BG from "../src/images/sf_bg.png";
import APP_Download from "../src/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg";

function Header() {
  return (
    <section className="bg-white dark:bg-gray-900 mt-20">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16">
        {/* Grid layout with two columns */}
        <div className="grid grid-cols-8 gap-4">
          {/* Left Part */}
          <div className="col-span-3 ">
            <div className="  border-gray-200 dark:border-gray-700 rounded-lg p-8 md:p-12 mb-8">
              <h1 className="text-gray-900 dark:text-white text-3xl md:text-5xl font-bold mb-2">
                SeeFood: AI Menu Reader
              </h1>
              <p className="text-lg font-normal text-gray-500 dark:text-gray-400 my-10">
                Just snap a menu with your phone, and SeaFood brings the dishes
                to life with vivid images.
              </p>
              <a href="https://apps.apple.com/us/app/seefood-ai-menu-reader/id6630388053">
                <img
                  src={APP_Download}
                  alt="Download on the App Store"
                  className="w-auto h-12"
                />
              </a>
            </div>
          </div>
          <div className="flex justify-center items-center col-span-5">
            <img
              src={SF_BG}
              alt="Profile"
              className="w-full h-auto max-w-[600px] lg:max-w-[800px] xl:max-w-[1000px] "
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Header;
