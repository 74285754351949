import React from "react";
import DEMO from "../src/videos/SeeFoodDemo.MP4";

function VideoWithComments() {
  return (
    <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8  rounded-lg shadow-sm dark:border-gray-700 bg-white dark:bg-gray-800 p-8">
        {/* Video Section */}
        <div className="w-full flex justify-center">
          <video
            className="w-auto h-auto max-w-full max-h-screen 
                     sm:max-w-[150px] md:max-w-[200px] lg:max-w-[240px] xl:max-w-[300px]"
            controls
            autoPlay
          >
            <source src={DEMO} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        {/* Comments Section */}
        <div className="grid gap-8">
          {/* Comment 1 */}
          <figure className="flex flex-col items-center justify-center p-8 text-center bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700">
            <blockquote className="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 dark:text-gray-400">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                Sweet!!
              </h3>
              <p className="my-4">
                " So handy for dishes in foreign languages."
              </p>
            </blockquote>
            <figcaption className="flex items-center justify-center">
              <img
                className="rounded-full w-9 h-9"
                src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/karen-nelson.png"
                alt="profile picture"
              />
              <div className="space-y-0.5 font-medium dark:text-white text-left ms-3">
                <div> Reddie Users</div>
              </div>
            </figcaption>
          </figure>

          {/* Comment 2 */}
          <figure className="flex flex-col items-center justify-center p-8 text-center bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700">
            <blockquote className="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 dark:text-gray-400">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                Very useful
              </h3>
              <p className="my-4">
                " Used in an airport today to buy a burrito; very useful little
                tool. Highly recommend."
              </p>
            </blockquote>
            <figcaption className="flex items-center justify-center">
              <img
                className="rounded-full w-9 h-9"
                src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/roberta-casas.png"
                alt="profile picture"
              />
              <div className="space-y-0.5 font-medium dark:text-white text-left ms-3">
                <div> iOS Users Quakers!!!s</div>
              </div>
            </figcaption>
          </figure>

          {/* Comment 3 */}
          <figure className="flex flex-col items-center justify-center p-8 text-center bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700">
            <blockquote className="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 dark:text-gray-400">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                Fantastic idea!
              </h3>
              <p className="my-4">
                " i have always wished restaurants include pictures of the food
                to get an idea of serving size and volume of
                sauce/cheese/condiments!"
              </p>
            </blockquote>
            <figcaption className="flex items-center justify-center">
              <img
                className="rounded-full w-9 h-9"
                src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/jese-leos.png"
                alt="profile picture"
              />
              <div className="space-y-0.5 font-medium dark:text-white text-left ms-3">
                <div>Jese Leos</div>
              </div>
            </figcaption>
          </figure>
        </div>
      </div>
    </div>
  );
}

export default VideoWithComments;
